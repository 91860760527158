.App {
  width: 100%;
  height: 100%;
  text-align: center;
}

#info {
  background: transparent;
	position: absolute;
	top: 92vh;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	text-align: left;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 1;
  vertical-align: bottom;
}

a{
  color: aliceblue;
}